.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;

    li {
        position: relative;
        margin-right: 12px;
        font-size: 18px;
        line-height: 18px;
        color: var(--color-black);

        &.active a {
            color: var(--color-blue);
        }

        a {
            font-family: var(--font-primery);
            font-size: 18px;
            line-height: 18px;
            color: var(--color-black);
            text-decoration: none;
        }
    }
}
